.hero__wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .hero__content,
  .hero__img {
    width: 50%;
    font-size: 1.1rem;
  }
  
  .hero__img img {
    width: 100%;
  }
  
  .hero__content {
    padding-top: 40px; 
    width: 45%;
  }
  
  .hero__content h2 {
    font-size: 3rem;
    line-height: 65px;
  }
  
  .hero__content p {
    margin-top: 40px;
    font-size: 1.1rem;
  }
  
  .highlight {
    color: var(--primary-color);
  }
  
  .hero__btns {
    margin-top: 40px;
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }
  
  .primary__btn,
  .secondary__btn {
    padding: 0.8rem 1.5rem;
    border: none;
    color: white;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .primary__btn {
    background: rgba(6, 11, 26, 1);
    border: 2px solid var(--btn-primary-bg);
  }
  
  .secondary__btn {
    background: #379cf6;
    color: white;
  }
  
  .primary__btn:hover,
  .secondary__btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .primary__btn::before,
  .secondary__btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
  }
  
  .primary__btn:hover::before,
  .secondary__btn:hover::before {
    opacity: 1;
    animation: outline-flash 1s infinite;
  }
  
  .primary__btn::before {
    background-color: #0b2652;
  }
  
  .secondary__btn::before {
    background: #1662a4;
  }
  
  @keyframes outline-flash {
    0% {
      outline: 2px solid white;
    }
    50% {
      outline: 2px solid rgba(255, 255, 255, 0);
    }
    100% {
      outline: 2px solid white;
    }
  }
  
  @media only screen and (max-width: 992px) {

    .hero__content h2 {
      font-size: 2rem;
      line-height: 50px;
    }

    .hero__img {
      width: 50%;
      font-size: 1.1rem;
    }

    .hero__content{
      margin-right: 30px;
    }

    .hero__wrapper{
      display: flex;
      justify-content: left;
    }


  }
  
  @media only screen and (max-width: 768px) {
    .hero__content h2 {
      font-size: 1.5rem;
      line-height: 45px;
    }
  
    .hero__content p {
      font-size: 0.9rem;
    }
  
    .primary__btn,
    .secondary__btn {
      font-size: 0.8rem;
    }
  
    .hero__content,
    .hero__img {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .hero__content,
    .hero__img {
      width: 100%;
    }

    .hero__img {
      margin-top: 50px;
    }
  
    .hero__wrapper {
      flex-direction: column;
    }
  }
  