.project__content {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .project__content h2 {
    margin-bottom: 25px;
  }
  
  .project__item {
    width: 253px;
    height: 294px;
    background: linear-gradient(315deg, #0b2652 26%, #0a0b4e 74%);
    border-radius: 30px;
    overflow: hidden;
    position: relative;
  }
  
  .project__img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: white;
    overflow: hidden;
    position: relative;
  }
  
  .project__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .project__details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .project__item:hover .project__details {
    transform: translateY(0);
  }
  
  .project__details h4 {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
  }
  
  .project__details p {
    color: white;
    font-size: 0.9rem;
  }
  
  .project__wrapper {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }
  
  .choose__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
  }
  
  #web__button,
  #graphic__button,
  #dev__button,
  #marketing__button {
    font-size: 0.9rem;
    padding: 0.8rem 1.5rem;
    border-radius: 30px;
    border: none;
    background: transparent;
    color: white;
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top: 15px;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }
  
  #web__button:hover,
  #graphic__button:hover,
  #dev__button:hover,
  #marketing__button:hover {
    background-color: #0b2652;
  }
  
  #web__button.active,
  #graphic__button.active,
  #dev__button.active,
  #marketing__button.active {
    border: 2px solid #379cf6;
    background-color: rgba(6, 11, 26, 1);
    color: white;
  }
  
  #web {
    padding-top: 0;
  }

  @media only screen and (max-width: 992px) {
    .project__item {
      width: 30%;
      margin-bottom: 30px;
    }
  
    .project__wrapper {
      flex-wrap: wrap;
    }
  
    .project__content h2 {
      font-size: 1.5rem;
    }
  
    .project__details h4 {
      font-size: 1rem;
    }
  
    .project__details p {
      font-size: 0.8rem;
    }
  
    .scrolling-journey-bar {
      display: block;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .project__item {
      width: 30%;
    }
  
    .project__wrapper {
      flex-wrap: wrap;
    }
  
    .project__content h2 {
      font-size: 1.5rem;
    }
  
    .project__details h4 {
      font-size: 1rem;
    }
  
    .project__details p {
      font-size: 0.8rem;
    }
  
    .scrolling-journey-bar {
      display: block;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .project__item {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .project__wrapper {
      display: flex;
    }
  
    .choose__buttons {
      flex-direction: column;
    }
  
    #web__button,
    #graphic__button,
    #dev__button,
    #marketing__button {
      font-size: 0.8rem;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .choose__buttons {
      flex-direction: column;
    }
  }
  