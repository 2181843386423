
.about__content_desc{
    margin-top: 30px;
}

.about__wrapper{
    display: flex;
    column-gap: 2rem;
}

.about__content, .about__img{
    width: 50%;
}

.about__img{
    border-radius: 0.8rem;
}

.about__img img{
    width: 100%;
    height: 97.5%;
    object-fit: cover;
    border-radius: 0.8rem;
}

.choose__us-item{
    padding: 20px 20px 20px 0;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 15px;
}

.choose__item-wrapper{
    margin-top: 20px;

}

.choose__us-icon{
     width: 50px;
     height: 60px;
     padding: 0px 30px;
     margin-right: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     border: 1px solid var(--primary-color);
     border-radius: 15px;
}

.choose__us-icon i{
    color: var(--primary-color);
    font-size: 2rem;

}

.choose__us-title{
    color: white;
    font-weight: 500;

}

#about{
    margin-bottom: 30px;
}

@media only screen and (max-width: 992px){
    .about__content h2{
        font-size: 1.5rem;
    }

    .about__img{
        width: 40%;
    }

    .about__img img{
        transform: scale(1);
        margin-top: 50px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 768px){
    .about__wrapper{
        flex-direction: column;
    }

    .about__content{
        width: 100%;
    }

    .about__content{
        margin-bottom: 30px;
    }

    .about__img {
        width: 100%;
        text-align: center;
      }
      
      .about__img img {
        width: 50%;
      }



}

